import React from 'react';

// @ts-ignore - import image
import logo from '../../images/codesqwerl_logo.png';
import './index.scss';

const Loader = () => (
  <div className="loader-container">
    <div className="loader-container-image">
      <img src={logo} alt="..." />
    </div>
  </div>
);

export default Loader;
