import { faGithubAlt, faLinkedinIn, faTwitter } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { graphql, StaticQuery } from 'gatsby';
import React from 'react';

import { GraphQlSiteMetadataQuery } from '../../types';
import './index.scss';

export const Footer = ({ data }: { data: GraphQlSiteMetadataQuery }) => (
  <footer className="footer">
    <section className="footer-social">
      <ul className="social">
        <li>
          <a
            title="Check out my Github projects"
            href={`https://github.com/${data.site.siteMetadata.user}/`}>
            <FontAwesomeIcon aria-hidden="true" icon={faGithubAlt} />
          </a>
        </li>
        <li>
          <a
            title={`Tweet @${data.site.siteMetadata.user}`}
            href={`https://twitter.com/${data.site.siteMetadata.user}`}>
            <FontAwesomeIcon aria-hidden="true" icon={faTwitter} />
          </a>
        </li>
        <li>
          <a
            title="Connect on LinkedIn"
            href={`https://linkedin.com/in/${data.site.siteMetadata.user}`}>
            <FontAwesomeIcon aria-hidden="true" icon={faLinkedinIn} />
          </a>
        </li>
      </ul>
    </section>
    <section className="footer-copyright">
      &copy; 2010-{new Date().getFullYear()}. {data.site.siteMetadata.title}.
    </section>
  </footer>
);

/* istanbul ignore next: ignore StaticQuery wrapper */
const FooterContainer = () => (
  <StaticQuery
    query={graphql`
      query {
        site {
          siteMetadata {
            title
            user
          }
        }
      }
    `}
    render={(data: GraphQlSiteMetadataQuery) => <Footer data={data} />}
  />
);

export default FooterContainer;
