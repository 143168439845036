import { graphql, StaticQuery } from 'gatsby';
import React from 'react';
import Helmet from 'react-helmet';

import { GraphQlSiteMetadataQuery, PageState } from '../../types';
import Footer from '../Footer';
import Header from '../Header';
import Loader from '../Loader';
import './index.scss';

export type LayoutProps = PageState;

export class Layout extends React.PureComponent<LayoutProps & { data: GraphQlSiteMetadataQuery }> {
  render() {
    const {
      children,
      data: {
        site: { siteMetadata: info }
      },
      loading
    } = this.props;
    return (
      <main>
        <Helmet>
          <html lang="en" />
          <meta name="og:description" content={info.description} />
          <meta name="docsearch:version" content="2.0" />
          <meta name="og:site_name" content={info.title} />
          <meta name="og:type" content="website" />
          <meta name="og:url" content={info.url} />
          <meta name="twitter:site" content={`@${info.user}`} />
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no"
          />
          <title>{info.title}</title>
        </Helmet>
        {loading && <Loader />}
        <Header />
        <section className="content">{children}</section>
        <Footer />
      </main>
    );
  }
}

/* istanbul ignore next: ignore StaticQuery wrapper */
const LayoutContainer = (props: LayoutProps) => (
  <StaticQuery
    query={graphql`
      query {
        site {
          siteMetadata {
            description
            title
            url
            user
          }
        }
      }
    `}
    render={(data: GraphQlSiteMetadataQuery) => <Layout data={data} {...props} />}
  />
);

export default LayoutContainer;
