import { Link } from 'gatsby';
import React from 'react';

// @ts-ignore - import image
import logo from '../../images/codesqwerl_logo.png';
import './index.scss';

const Header = () => {
  return (
    <header className="header">
      <div className="header-logo">
        <Link to={'/'}>
          <img src={logo} />
        </Link>
      </div>
    </header>
  );
};

export default Header;
